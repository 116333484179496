import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visibleListing: false,
            category: [],
            vendors: [],
            listings: [],
            recordsVendors: [],
            records: [],
            loading: true
        };
        this.columns = [
            {
                key: "category",
                text: "Category",
                className: "category",
                align: "left",
                sortable: true,
            },
            {
                key: "total_listings",
                text: "Total Listings",
                className: "category",
                align: "left",
                sortable: true,
            },

            {
                key: "action",
                text: "Listings",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <>
                            <Fragment>
                                <button onClick={() => this.showListings(record.category)}
                                    className="btn btn-danger btn-sm" >
                                    <i className="fa fa-eye"></i>
                                </button>
                            </Fragment>
                        </>
                    );
                }
            },
            {
                key: "action",
                text: "Vendors",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <>
                            <Fragment>
                                <button onClick={() => this.show(record.category)}
                                    className="btn btn-danger btn-sm" >
                                    <i className="fa fa-eye"></i>
                                </button>
                            </Fragment>
                        </>
                    );
                }
            },



            // {
            //     key: "action",
            //     text: "Action",
            //     className: "action",
            //     width: 100,
            //     align: "left",
            //     sortable: false,
            //     cell: record => { 
            //         return (
            //             <>
            //             <Fragment>
            //                 <button onClick={this.show.bind(this)}
            //                     className="btn btn-danger btn-sm" >
            //                     <i className="fa fa-eye"></i>
            //                 </button>
            //             </Fragment>
            //             </>
            //         );
            //     }
            // }
        ];
        this.Listingcolumns = [
            {
                key: "name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "address",
                text: "Address",
                className: "address",
                align: "left",
                sortable: true,
            },
            {
                key: "mobileNumber",
                text: "Mobile Number",
                className: "mobile_number",
                align: "left",
                sortable: true,
            },
            {
                key: "views",
                text: "views",
                className: "views",
                align: "left",
                sortable: true,
            },
        ];

        this.Vendorcolumns = [
            {
                key: "name",
                text: "UserName",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true,
            },
            {
                key: "mobileNumber",
                text: "Mobile Number",
                className: "address",
                align: "left",
                sortable: true,
            },


            // {
            //     key: "action",
            //     text: "Action",
            //     className: "action",
            //     width: 100,
            //     align: "left",
            //     sortable: false,
            //     cell: record => { 
            //         return (
            //             <>
            //             <Fragment>
            //                 <button onClick={this.show.bind(this)}
            //                     className="btn btn-danger btn-sm" >
            //                     <i className="fa fa-eye"></i>
            //                 </button>
            //             </Fragment>
            //             </>
            //         );
            //     }
            // }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: true,
                csv: true
            }
        }



        // this.extraButtons =[
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //                 <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //     },
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //             <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //         onDoubleClick:(event)=>{
        //             console.log("doubleClick")
        //         }
        //     },
        // ]
    }

    editRecord(record) {
        console.log("Edit Record", record);
    }

    deleteRecord(record) {
        console.log("Delete Record", record);
    }
    onlyUnique = (value, index, self) => {
        console.log('vii', value.vendor_id.email)
        return self.indexOf(value) === index;
    }
    show = async (cat) => {
        let data = {
            category: cat
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/getvendorbycategory`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    listings: res.data.data
                })
                const result = [];
                const map = new Map();
                for (const item of res.data.data) {
                    if (item.vendor_id != null) {
                        if (!map.has(item.vendor_id._id)) {
                            map.set(item.vendor_id._id, true);    // set any value to Map
                            result.push(
                                item.vendor_id
                            );
                        }
                    }

                }
                this.setTableDataVendor(result)
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
        this.setState({ visible: true });
    }

    hideListing = () => {
        this.setState({ visibleListing: false });
    }

    showListings = async (cat) => {
        let data = {
            category: cat
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/getvendorbycategory`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setTableDataListings(res.data.data)
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
        this.setState({ visibleListing: true });
    }

    hide() {
        this.setState({ visible: false });
    }

    componentDidMount() {
        if (!localStorage.getItem("admin_token")) {
            this.props.history.push("/auth/login")
        } else {
            this.getListingData()
        }
    }
    getListingData = async () => {
        this.setState({
            loading: false
        })
        await axios.get(`${process.env.REACT_APP_API}/api/auth/getcounts`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    category: res.data.data,
                })
                this.setTableData()
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
            this.setState({
                loading: true
            })
        })
    }
    setTableDataVendor = (data) => {
        console.log("faisal", this.state.category)
        let data1 = []
        data && data.map((dat, i) => {
            return data1.push({

                "id": i,
                "name": dat.name,
                "email": dat.email,
                "mobileNumber": dat.mobile_number,

            })
        })
        this.setState({
            recordsVendors: data1
        })

    }
    setTableDataListings = (data) => {
        console.log("faisal", this.state.listings)
        let data1 = []
        data && data.map((dat, i) => {
            return data1.push({

                "id": i,
                "name": dat.Name,
                "address": dat.Address.Address,
                "mobileNumber": dat.Mobile_Number,
                "views": dat.Views.length
            })
        })
        this.setState({
            recordsListings: data1
        })

    }
    setTableData = () => {
        console.log("faisal", this.state.category)
        let data1 = []
        this.state.category && this.state.category.map((data, i) => {
            return data1.push({

                "id": i,
                "category": data.category,
                "total_listings": data.listingscount.toString()

            })
        })
        this.setState({
            records: data1
        })

    }

    render() {
        return (

            <>
                <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header">
                            <h3 class="mb-0">Category</h3>
                        </div>
                        {this.state.loading ?
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                extraButtons={this.extraButtons}
                            />
                            :
                            <div className="loader">
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        }
                    </div>
                </div>
                <Rodal visible={this.state.visibleListing} onClose={this.hideListing} width="900" height="600" >
                    <div className="header_view_popup">View Detail</div>
                    <div className="main_listing_view">
                        <div className="listing_goes">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.recordsListings}
                                columns={this.Listingcolumns}
                                extraButtons={this.extraButtons}
                            />
                        </div>
                    </div>
                </Rodal>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} width="900" height="600" >
                    <div className="header_view_popup">View Detail</div>
                    <div className="main_listing_view">
                        <div className="listing_goes">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.recordsVendors}
                                columns={this.Vendorcolumns}
                                extraButtons={this.extraButtons}
                            />
                        </div>
                    </div>
                </Rodal>
            </>


        )
    }
}

export default Category;;