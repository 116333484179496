import React, { Component } from 'react';
import Header from 'components/Headers/Header';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios"
import {
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from "reactstrap";

class AddBlogs extends Component {

    state = {
        blogTitle: '',
        metaPara: '',
        cate: '',
        MetaTitle: '',
        MetaKey: '',
        tags: [],
        input: '',
        tagsFilterd: [],
        category: [],
        cat: "",
        Image: null,
        suggestion: []
    }
    submitBlog = async () => {
        let { blogTitle, metaPara, MetaTitle, cate, tags, description, Image, MetaKey } = this.state
        let title=""
        if(this.state.blogTitle){
          let title1 = this.state.blogTitle.toLowerCase();
           title = title1.replace(/[^a-zA-Z0-9]+/g,'-');
        }
        if (!blogTitle || !metaPara || !MetaTitle || !cate || !tags || !description || !Image || !MetaKey) {
            alert("Please Enter All Fields")
        }
    
        else {
            let data = new FormData()
            data.append("Title", this.state.blogTitle)
            data.append("Meta_description", this.state.metaPara)
            data.append("Meta_title", this.state.MetaTitle)
            data.append("Meta_keyword", this.state.MetaKey)
            data.append("Category", this.state.cate)
            data.append("Tags", JSON.stringify(this.state.tags))
            data.append("Description", this.state.description)
            data.append("Blog_pic", this.state.Image)
            data.append("Slug", title)
            console.log(data)
            const Config = {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    // that.setState({ filesProgress: percentCompleted });
                },

                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_token")}`
                }


            }
            await axios.post(`${process.env.REACT_APP_API}/api/auth/addblog`, data, Config).then((res) => {
                if (res.data.Error == false) {
                    if(res.data.msg=="Blog title already in use"){
                        alert("Blog title already in use")
                    }
                    else{
                        this.props.history.push("/admin/blogs")

                    }
                   

                }
                else if (res.data.token == false) {
                    localStorage.removeItem("admin_token")
                    this.props.history.push("/auth/login")
                }
            })
        }

    }
    componentDidMount() {
        this.getBlogCat()
    }
    addtages = (e) => {
        // console.log(e.keyCode)
        if (e.target.value.length && e.code === "Enter" && !this.state.tags.includes(e.target.value)) {
            this.setState({ tags: [...this.state.tags, e.target.value], input: "", tagsFilterd: [] })
        } else {
            // console.log(e)
        }
    }
    fillterSuggestions = async (value) => {
        this.setState({ input: value })
        if (value == "") {
            this.setState({ tagsFilterd: [] })
        } else {

            await axios.post(`${process.env.REACT_APP_API}/api/auth/searchtags`, { tag: value }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_token")}`
                }
            }).then((res) => {
                if (res.data.Error == false) {
                    let suggestions = res.data.tags
                    let suggestion = []
                    suggestions.map((data) => {
                        suggestion.push(data.Tags)
                    })
                    let fiterdsuggestion = suggestion.filter(data => {
                        if (!this.state.tags.includes(data)) {
                            return data
                        }

                    })
                    this.setState({ tagsFilterd: fiterdsuggestion })
                }
                else if (res.data.token == false) {
                    localStorage.removeItem("admin_token")
                    this.props.history.push("/auth/login")
                }
            })

            // console.log(fiterdsuggestion)
        }
    }

    slectTag = (value) => {
        this.setState({ tags: [...this.state.tags, value], input: "", tagsFilterd: [] })
    }

    removeTags = (index) => {
        let neObj = this.state.tags
        if (index > -1) {
            neObj.splice(index, 1);
        }
        this.setState({ tags: [...neObj] })
    }

    handleCkEditorDescription = (event, editor) => {
        const data = editor.getData();
        this.setState({
            description: data
        })
        // console.log(data);
    }
    addCategory = async () => {

        if (this.state.cat) {
            let data = {
                Name: this.state.cat
            }
            await axios.post(`${process.env.REACT_APP_API}/api/auth//addblogcategory`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_token")}`
                }
            }).then((res) => {
                if (res.data.Error == false) {
                    this.setState({
                        cat: ""
                    })
                    this.getBlogCat()
                }
                else if (res.data.token == false) {
                    localStorage.removeItem("admin_token")
                    this.props.history.push("/auth/login")
                }
            })
        }

    }
    getBlogCat = async () => {
        await axios.get(`${process.env.REACT_APP_API}/api/auth/getblogcategories`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("admin_token")}`
            }
        }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    category: res.data.Data,
                    cate: res.data.Data[0]._id
                })
            }
            else if (res.data.token == false) {
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
            }
        })
    }
    //       removeCategory=async(id)=>{
    // let data1={
    //     id:id
    // }
    // await axios.post(`${process.env.REACT_APP_API}/api/auth/deleteblogcategory`,data1).then((res)=>{
    //     if(res.data.Error==false){  
    //    this.getBlogCat()
    //     }
    //      })
    //       }
    render() {
        console.log(this.state.category)
        return (
            <>
                <Header />
                <div className="mt--7 container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="shadow card">
                                <div className="border-0 card-header">
                                    <h3 className="mb-0">Add Blog</h3>
                                </div>
                                <div className="main_blogs">
                                    <div className="list_blog">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-city">Blog Title</label>
                                                    <input id="input-city" placeholder="" onChange={(e) => this.setState({ blogTitle: e.target.value })} type="text" className="form-control-alternative form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-control-label">Category</label>
                                                    <select className="form-control-alternative form-control" onChange={(e) => { console.log(e.target.value); this.setState({ cate: e.target.value }) }}>
                                                        {this.state.category.map((data) => {
                                                            return <option value={data._id}>{data.Name}</option>
                                                        })}

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-city">Meta Title</label>
                                                    <input id="input-city" placeholder="" type="text" onChange={(e) => this.setState({ MetaTitle: e.target.value })} className="form-control-alternative form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-control-label">Meta Keyword</label>
                                                    <input placeholder="" type="text" onChange={(e) => this.setState({ MetaKey: e.target.value })} className="form-control-alternative form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label">Tags</label>
                                                    <div className='border setting_tags p-2 form-control-alternative form-control'>
                                                        {this.state.tags.map((data, index) => {
                                                            return (
                                                                <span className="alert alert-success mx-1 p-1" >
                                                                    {data}  <span className=" remove_tags mx-1"
                                                                        onClick={() => { this.removeTags(index) }}
                                                                    >x</span>
                                                                </span>
                                                            )
                                                        })}
                                                        <div className='tagesinput'>
                                                            <input type="text"
                                                                className='border-0'
                                                                placeholder='add tages...'
                                                                value={this.state.input}
                                                                onKeyDown={(e) => this.addtages(e)}
                                                                onChange={(e) => { this.fillterSuggestions(e.target.value) }}
                                                            />
                                                            {this.state.tagsFilterd.length > 0 && (<div>
                                                                {this.state.tagsFilterd.map((data, index) => (
                                                                    <span
                                                                        onClick={() => this.slectTag(data)}
                                                                    >{data}</span>
                                                                ))}
                                                                {/* <span></span> */}
                                                            </div>)}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-city">Meta Description</label>
                                                    <textarea className="form-control-alternative form-control" type="text" onChange={(t) => this.setState({ metaPara: t.target.value })}></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-city">Image</label>
                                                    <input type="file" className="form-control-alternative form-control" onChange={(e) => this.setState({ Image: e.target.files[0] })} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-control-label" htmlFor="input-city">Blog Description</label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={{ removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'], }}
                                                        data={this.state.description}
                                                        onInit={(editor) => { }}
                                                        onChange={this.handleCkEditorDescription}
                                                    />
                                                    {/* <textarea className="form-control-alternative form-control" type="text" onChange={(t) => this.setState({blogPara:t.target.value})}></textarea> */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="submit_blog">
                                        <button className='btn btn-primary' onClick={this.submitBlog}>submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                                <div className="shadow card">
                                    <div className="border-0 card-header">
                                        <h3 className="mb-0">Add Category</h3>
                                    </div>
                                    
                                    <div className="list_add_cate">
                                        {this.state.category.map((data)=>{
                                            return  <p className="main_list_category_view">{data.Name}
                                             </p> 
                                        })}
                                        <input placeholder="Enter Category" value={this.state.cat} className="form-control-alternative form-control" onChange={(e)=>{this.setState({cat:e.target.value})}} />
                                    </div>
                                    <div className="add_category_div">
                                        <div className="main_add_btn text-center">
                                            <button onClick={this.addCategory} type="button" className="btn btn-primary mb-3">Add Category</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    </div>

                </div>
            </>
        );
    }
}

export default AddBlogs;