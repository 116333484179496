import React, { Component } from 'react';
import Header from "components/Headers/Header.js";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";

class AddFaq extends Component {

  state = {
    question: '',
    asnwer: '',
    err: false
  };

  handlefaqsubmit = async () => {
    this.setState({
      err: false
    })
    if (!this.state.question || !this.state.asnwer) {
      this.setState({
        err: true
      })
    }
    else {
      let faq = {
        Question: this.state.question,
        Answer: this.state.asnwer
      }
      await axios.post(`${process.env.REACT_APP_API}/api/auth/addfaqs`, faq, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_token")}`
        }
      }).then((res) => {
        if (res.data.Error == false) {
          this.props.history.push("/admin/faq")
        }
        else if (res.data.token == false) {
          localStorage.removeItem("admin_token")
          this.props.history.push("/auth/login")
        }
      })
    }

  }
  handleCkEditorDescription = (event, editor) => {
    console.log(editor.getData())
    const data = editor.getData();
    this.setState({
      asnwer: data
    })
  }
  render() {
    return (
      <>
        <Header />
        <div className="mt--7 container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="shadow card">
                <div className="border-0 card-header">
                  <h3 className="mb-0">FAQs</h3>
                </div>
                <div className="main_add_faq">
                  {this.state.err ? <p style={{ color: "red", marginBottom: "5px" }}>Please Enter All Fields</p> : ""}
                  <div class="form-group">
                    <label class="form-control-label">Question</label>
                    <input placeholder="" type="text" onChange={(e) => this.setState({ question: e.target.value })} class="form-control-alternative form-control" />
                  </div>
                  <div class="form-group">
                    <label class="form-control-label">Answer</label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{ removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'], }}
                      data={this.state.faq_answer}
                      onInit={(editor) => { }}
                      onChange={this.handleCkEditorDescription}
                    />
                    {/* <textarea type="text" onChange={(e) => this.setState({ asnwer: e.target.value })} className="form-control-alternative form-control"></textarea> */}
                  </div>
                  <div className="submit_faq">
                    <button type="button" className="btn btn-primary" onClick={this.handlefaqsubmit}>submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddFaq;
