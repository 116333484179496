import React, { Component, Fragment } from 'react';
import { render} from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


class Vendors extends Component {
    constructor(props) {
        super(props);
        this.state = { visible: false
            ,user:{},
            listings:[],
            approved:null,
            Name:"",            
            Email:"",
            Password:"",
            Mobile_Number:"",
            Status:false,
            id:"",
            AdminStatus:false,
            loading:true
        };
        this.columns = [
            {
                key: "name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true
            },
            {
                key: "mobileNumber",
                text: "Mobile Number",
                className: "mobilenumber",
                align: "left",
                sortable: true
            },
   
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => { 
                    return (
                        <>
                        <Fragment>
                            <button onClick={()=>{this.show(record)}}
                                className="btn btn-danger btn-sm" >
                                <i className="fa fa-pencil-square-o"></i>
                            </button>
                        </Fragment>
                        </>
                    );
                }
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            button: {
                excel: true,
                csv: true
            }
        }
        
        this.state = {
            records: []
        }
        // this.extraButtons =[
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //                 <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //     },
        //     {
        //         className:"btn btn-primary buttons-pdf",
        //         title:"Export TEst",
        //         children:[
        //             <span>
        //             <i className="fa fa-user fa fa-print" aria-hidden="true"></i>
        //             </span>
        //         ],
        //         onClick:(event)=>{
        //             console.log(event);
        //         },
        //         onDoubleClick:(event)=>{
        //             console.log("doubleClick")
        //         }
        //     },
        // ]
    }

    editRecord(record) {
        console.log("Edit Record", record);
    }

    deleteRecord(record) {
        console.log("Delete Record", record);
    }

    show=async(record)=> {
        let id=record.id
        await axios.get(`${process.env.REACT_APP_API}/api/auth/admingetvendordetails/${id}`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("admin_token")}`
          }
          }).then((res)=>{
            if(res.data.Error==false){
                console.log(res.data.Data)
                this.setState({
                    id:id,
                    Name:res.data.Data.name,
                    Email:res.data.Data.email,
                    Password:res.data.Data.password,
                    Mobile_Number:res.data.Data.mobile_number,
                    Status:res.data.Data.status,
                    AdminStatus:res.data.Data.AdminStatus

                })
            }
            else if(res.data.token==false){
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
              }    
            })
        this.setState({ visible: true });
      }
     
      hide() {
        this.setState({ visible: false });
      }

    componentDidMount(){
        if(!localStorage.getItem("admin_token")){
            this.props.history.push("/auth/login")
            }else{
              this.getListingData()
            }
   }
   getListingData=async ()=>{
     this.setState({
        loading:false
    })
    await axios.get(`${process.env.REACT_APP_API}/api/auth/getvendors`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_token")}`
      }
      }).then((res)=>{
        if(res.data.Error==false){
            this.setState({
                listings:res.data.Data,
            })
            this.setTableData()
        }  
        else if(res.data.token==false){
            localStorage.removeItem("admin_token")
            this.props.history.push("/auth/login")
          } 
          this.setState({
            loading:true
        }) 
        })
   }
   setTableData=()=>{
       console.log("faisal",  this.state.listings)
       let data1=[]
       this.state.listings && this.state.listings.map((data,i)=>{
        return data1.push({
            
                "id": data._id,
                "name": data.name,
                "email": data.email,
                "mobileNumber":data.mobile_number              
         })
       })
       this.setState({
           records:data1
       })
   }
   editUser=async(e)=>{
  e.preventDefault()
  let data ={
      id:this.state.id,
    name: this.state.Name,
    mobile_number: this.state.Mobile_Number,
    email: this.state.Email,
    password: this.state.Password,
    status: this.state.Status,
    AdminStatus:this.state.AdminStatus

  }
  await axios.post(`${process.env.REACT_APP_API}/api/auth/adminupdatevendor`,data,{
    headers: {
      Authorization: `Bearer ${localStorage.getItem("admin_token")}`
  }
  }).then((res)=>{
    if(res.data.Error==false){
    this.getListingData()
    this.setState({visible:false})
    }
    else if(res.data.token==false){
        localStorage.removeItem("admin_token")
        this.props.history.push("/auth/login")
      }    
    })
   }
   phoneInput = (e) => {
    const ch = String.fromCharCode(e.which)
    if (!(/[0-9]/.test(ch))) {
        e.preventDefault();
    }
    else if (this.state.Mobile_Number.length >= 11) {
        e.preventDefault()
    }
}
    render() {
        return (

                <>
            <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header">
                            <h3 class="mb-0">Vendors</h3>
                        </div>
                        {this.state.loading
                        ?
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            extraButtons={this.extraButtons}
                        />
                              :              
                                      <div className="loader">
                                       <Loader
                                       type="Puff"
                                       color="#00BFFF"
                                       height={100}
                                       width={100}
                                     />
                                     </div>
                        }
                    </div>
                </div>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} width="900" height="600" >
                    <div className="header_view_popup">View Detail</div>
                    <div className="main_listing_view">
                        <div className="listing_goes">
                        <form>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="Name">Name</label>
                                                <input value={this.state.Name} onChange={(e)=>{this.setState({Name:e.target.value})}} type="text" className="form-control" id="Name" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="Email">Email</label>
                                                <input value={this.state.Email} onChange={(e)=>{this.setState({Email:e.target.value})}} type="text" className="form-control" id="Email" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="password">Password</label>
                                                <input value={this.state.Password} onChange={(e)=>{this.setState({Password:e.target.value})}} type="text" className="form-control" id="password" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="mobile">Mobile Number</label>
                                                <input value={this.state.Mobile_Number} onChange={(e)=>{this.setState({Mobile_Number:e.target.value})}} onKeyPress={this.phoneInput} type="text" className="form-control" id="monile" />
                                            </div>
                                        </div>
                                        <div style={{cursor:"pointer",marginBottom:"20px"}} class="custom-control custom-switch">
                                       <input style={{cursor:"pointer"}}  value={this.state.AdminStatus} checked={this.state.AdminStatus}  onChange={()=>this.setState({AdminStatus:!this.state.AdminStatus})} type="checkbox" class="custom-control-input" id={`customSwitch1`} />
                                       <label style={{cursor:"pointer"}} class="custom-control-label"  htmlFor={`customSwitch1`}>Status</label>
                                           </div>

                                        <div className="main_submit text-right">
                                            <button onClick={this.editUser} type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                        </div>
                    </div>
                </Rodal>
            </>
                

        )
    }
}

export default Vendors;