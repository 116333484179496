import Header from 'components/Headers/Header';
import React, { Component, Fragment } from 'react';
import { render} from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { Link } from 'react-router-dom';
import axios from "axios"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


class Tag extends  Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            tag:"",
            delete:true,
            id:"",
            loading:true
        }
    }
    delete=async()=>{
        let data={
            id:this.state.id
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/deletetag`,data,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("admin_token")}`
          }
          }).then((res) => {
            if (res.data.Error == false) {
         
            }
            else if(res.data.token==false){
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
              }
        })
    }
        columns = [
            {
                key: "name",
                text: "Name",
                sortable: true,
            },
            {
                key: "status",
                text: "Status",
                sortable: true,
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => { 
                    return (
                        <>
                        <Fragment>
                        <button onClick={()=>this.show(record)}
                                className="btn btn-danger btn-sm" >
                                <i className="fa fa-eye"></i>
                            </button>

                        </Fragment>
                        </>
                    );
                }
            }
        ];
    config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            button: {
                excel: true,
                csv: true
            }
        }
        
 
    componentDidMount(){
        if(!localStorage.getItem("admin_token")){
            this.props.history.push("/auth/login")
            }else{
              this.getListingData()
            }
   }
   getListingData=async ()=>{
       this.setState({
           loading:false
       })
    await axios.get(`${process.env.REACT_APP_API}/api/auth/gettags`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_token")}`
      }
      }).then((res)=>{
        if(res.data.Error==false){
            this.setState({
                listings:res.data.Data,
            })
            this.setTableData(this.state.listings)
        }
        else if(res.data.token==false){
            localStorage.removeItem("admin_token")
            this.props.history.push("/auth/login")
          }
          this.setState({
              loading:true
          })    
        })
   }
   setTableData=(data)=>{
    let data1=[]
    data && data.map((dat,i)=>{
     return data1.push({
         
             "id": dat._id,
             "name": dat.Tags,
             "status":dat.Active ? "Active" :"Deactive"       
      })
    })
    this.setState({
        records:data1
    })

}
editTag=async (e)=>{
    e.preventDefault()
    let data={
        id:this.state.id,
        Tags:this.state.tag,
    }
    console.log(data)
    await axios.post(`${process.env.REACT_APP_API}/api/auth/updatetags`,data,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_token")}`
      }
      }).then((res) => {
      if (res.data.Error == false) {
          this.getListingData()
          this.setState({visible:false})
      }
      else if(res.data.token==false){
        localStorage.removeItem("admin_token")
        this.props.history.push("/auth/login")
      }
  })

  }
     show=async(record)=> {
        await axios.get(`${process.env.REACT_APP_API}/api/auth/gettag/${record.id}`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("admin_token")}`
          }
          }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    id:res.data.Data[0]._id,
                    tag: res.data.Data[0].Tags,
                    delete:res.data.Data[0].Active
                })
            }
               else if(res.data.token==false){
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
              }
        })
        this.setState({ visible: true });
      }
     
      hide() {
        this.setState({ visible: false });
      }


    render() {
        return (

                <>
            <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header d-flex align-items-center justify-content-between">
                            <h3 class="mb-0">Tags</h3>
                            <Link to="/admin/addtags" className="btn btn-primary">Add Tags</Link>
                        </div>
                        {this.state.loading ?
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            extraButtons={this.extraButtons}
                        />
                                             :              
                                      <div className="loader">
                                       <Loader
                                       type="Puff"
                                       color="#00BFFF"
                                       height={100}
                                       width={100}
                                     />
                                     </div>
                        }
                    </div>
                </div>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} width="900" height="600" >
                    <div className="header_view_popup">Edit</div>
                    <div className="main_listing_view">
                        <div className="listing_goes">
                        <form>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="Name">Name</label>
                                                <input value={this.state.tag} onChange={(e)=>{this.setState({tag:e.target.value})}} type="text" className="form-control" id="Name" />
                                            </div>
                                        </div>
                                        <div style={{cursor:"pointer",marginBottom:"20px"}} class="custom-control custom-switch">
                                       <input style={{cursor:"pointer"}}  value={this.state.delete} checked={this.state.delete}  onChange={()=>{this.delete(); this.setState({delete:!this.state.delete})}} type="checkbox" class="custom-control-input" id={`customSwitch1`} />
                                       <label style={{cursor:"pointer"}} class="custom-control-label"  htmlFor={`customSwitch1`}>Status</label>
                                           </div>
                                        <div className="submit_plan text-right">
                                            <button onClick={this.editTag} type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                        </form>
                        </div>
                    </div>
                </Rodal>

            </>
                

        )
    }

}

export default Tag;