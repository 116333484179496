import Header from 'components/Headers/Header'
import React, { Component } from 'react'


let suggestion = ["book", "mango", "apple"]

class AddTag extends Component {

    state = {
        tags: [],
        input: '',
        tagsFilterd: [],
    }



    addtages = (e) => {
        // console.log(e.keyCode)
        if (e.target.value.length && e.code === "Enter" && !this.state.tags.includes(e.target.value)) {
            this.setState({ tags: [...this.state.tags, e.target.value], input: "", tagsFilterd: [] })
        } else {
            // console.log(e)
        }
    }
    fillterSuggestions = (value) => {
        this.setState({ input: value })
        if (value == "") {
            this.setState({ tagsFilterd: [] })
        } else {


            let fiterdsuggestion = suggestion.filter(data => {
                if (!this.state.tags.includes(data)) {
                    return data.includes(value)
                }

            })
            this.setState({ tagsFilterd: fiterdsuggestion })
            // console.log(fiterdsuggestion)
        }
    }


    slectTag = (value) => {
        this.setState({ tags: [...this.state.tags, value], input: "", tagsFilterd: [] })
    }

    removeTags = (index) => {
        let neObj = this.state.tags
        if (index > -1) {
            neObj.splice(index, 1);
        }
        this.setState({ tags: [...neObj] })
    }



    render() {
        return (
            <>


                <Header />
                <div className="addtags">
                    <div className="mt--7 container-fluid">
                        <div className="shadow card">
                            <div className="border-0 card-header">
                                <h3 className="mb-0">Add Tags</h3>
                            </div>
                            <div className="form-group mb-0 add_custom_padding">
                                <label className="form-control-label">Tags</label>
                                <div className='border setting_tags p-2 form-control-alternative form-control'>
                                    {this.state.tags.map((data, index) => {
                                        return (
                                            <span className="alert alert-success mx-1 p-1" >
                                                {data}  <span className=" remove_tags mx-1"
                                                    onClick={() => { this.removeTags(index) }}
                                                >x</span>
                                            </span>
                                        )
                                    })}
                                    <div className='tagesinput'>
                                        <input type="text"
                                            className='border-0'
                                            placeholder='add tages...'
                                            value={this.state.input}
                                            onKeyDown={(e) => this.addtages(e)}
                                            onChange={(e) => { this.fillterSuggestions(e.target.value) }}
                                        />
                                        {this.state.tagsFilterd.length > 0 && (<div>
                                            {this.state.tagsFilterd.map((data, index) => (
                                                <span
                                                    onClick={() => this.slectTag(data)}
                                                >{data}</span>
                                            ))}
                                            {/* <span></span> */}
                                        </div>)}

                                    </div>
                                </div>
                                <div className="submit_tags mt-3">
                                    <button type="button" className="btn btn-primary">submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AddTag;