import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";

import LisTing from './views/listing'
import Users from './views/users'
import Vendors from './views/vendors'
import Category from './views/category'
import SubCategory from './views/subCategory'
import Plans from './views/plans'

import AddBlog from "views/AddBlog";
import EditBlog from "views/EditBlog";
import Blogs from './views/Blogs'

import Tag from "views/tags";

import faq from './views/faq/index'
import AddTag from "views/AddTag";
import Categories from "views/Categories";
import AddCategory from "views/AddCategory";
import AddFaq from "views/faq/addfaqs.js";






var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    status: true
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    status: false
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    status: false

  },
  {
    path: "/listings",
    name: "Listings",
    icon: "ni ni-bullet-list-67 text-red",
    component: LisTing,
    layout: "/admin",
    status: true

  },
  {
    path: "/category",
    name: "Category",
    icon: "fa fa-th-large text-yellow",
    component: Category,
    layout: "/admin",
    status: true

  },
  {
    path: "/sub-category",
    name: "Sub Categories",
    icon: "fa fa-th-large text-yellow",
    component: SubCategory,
    layout: "/admin",
    status: true

  },
  {
    path: "/users",
    name: "Users",
    icon: "fa fa-users text-red",
    component: Users,
    layout: "/admin",
    status: true

  },
  {
    path: "/Vendors",
    name: "Vendors",
    icon: "fa fa-address-book text-black",
    component: Vendors,
    layout: "/admin",
    status: true

  },
  {
    path: "/plans",
    name: "Subscription Plans",
    icon: "fa fa-file-text text-pink",
    component: Plans,
    layout: "/admin",
    status: true

  },
  {
    path: "/blogs",
    name: "blogs",
    icon: "ni ni-app text-blue",
    component: Blogs,
    layout: "/admin",
    status: true,
  },
  {
    path: "/tags",
    name: "Tags",
    icon: "fa fa-tags text-blue",
    component: Tag,
    layout: "/admin",
    status: true
  },
  {
    path: "/categories",
    name: "Blog Categories",
    icon: "fa fa-th-large text-black",
    component: Categories,
    layout: "/admin",
    status: true
  },
  {
    path: "/faq",
    name: "FAQs",
    icon: "fa fa-question-circle text-orange",
    component: faq,
    layout: "/admin",
    status: true

  },
  {
    path: "/addblog",
    component: AddBlog,
    layout: "/admin",
    status: true

  },
  {
    path: "/editblog/:id",
    component: EditBlog,
    layout: "/admin",
  },
  {
    path: "/addtags",
    // name: "Addtags",
    component: AddTag,
    layout: "/admin",
    status: true

  },
  {
    path: "/addcategory",
    // name: "Addtags",
    component: AddCategory,
    layout: "/admin",
    status: true
  },
  {
    path: "/addfaq",
    // name: "Addtags",
    component: AddFaq,
    layout: "/admin",
    status: false
  }
];
export default routes;
