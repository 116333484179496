import Header from 'components/Headers/Header';
import React, { Component, Fragment } from 'react';
import { render} from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { Link } from 'react-router-dom';
import axios from "axios"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


class Categories extends  Component {
    constructor(props) {
        super(props);
        
        this.state = {
            records: [],
            category:[],
            tag:"",
            delete:false,
            id:"",
            DeleteModal:false ,
            loading:true

        }
    }
    columns = [
        {
            key: "name",
            text: "Name",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "price",
            align: "left",
            sortable: true
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <>
                        <Fragment>
                            <button onClick={() => this.show(record)}
                                className="btn btn-danger btn-sm" >
                            <i className="fa fa-pencil-square-o"></i>
                            </button>
                            <button onClick={() => this.showDeleteModal(record)}
                                    className="btn btn-danger btn-sm" >
                              <i class="fa fa-trash" aria-hidden="true"></i>

                                </button>
                        </Fragment>
                    </>
                );
            }
        }
    ];
    config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        button: {
            excel: true,
            csv: true
        }
    }
    componentDidMount() {
        
        if(!localStorage.getItem("admin_token")){
        this.props.history.push("/auth/login")
        }else{
          this.getBlogCat()
        }
         
}
showDeleteModal=(record)=>{
    this.setState({
        plan_id:record.id,
        DeleteModal:true
    })
}
deleteData=async()=>{
    let data={
         id:this.state.plan_id

    }
    await axios.post(`${process.env.REACT_APP_API}/api/auth/deleteblogcategory`,data,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_token")}`
      }
      }).then((res) => {
        if (res.data.success == true) {
            this.setState({
                DeleteModal:false
            })
            this.getBlogCat()
        }
        else if(res.data.token==false){
            localStorage.removeItem("admin_token")
            this.props.history.push("/auth/login")
          }
    })

}
    show=async(record)=> {
        await axios.get(`${process.env.REACT_APP_API}/api/auth/getblogcategory/${record.id}`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("admin_token")}`
          }
          }).then((res) => {
            if (res.data.Error == false) {
                this.setState({
                    id:res.data.Data[0]._id,
                    tag: res.data.Data[0].Name,
                    delete:res.data.Data[0].Active
                    // delete:res.data.Data[0].Active
                })
            }
               else if(res.data.token==false){
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
              }
        })
        this.setState({ visible: true });
      }
     
      hide() {
        this.setState({ visible: false });
      }

      getBlogCat=async()=>{
          this.setState({
              loading:false
          })
        await axios.get(`${process.env.REACT_APP_API}/api/auth/getblogcategories`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("admin_token")}`
          }
          }).then((res)=>{
            if(res.data.Error==false){  
             this.setState({
                 category:res.data.Data,
             })
             this.setTableData()
            }
            else if(res.data.token==false){
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
              }
              this.setState({
                  loading:true
              })
             })
      }
      setTableData = () => {
        console.log("faisal", this.state.listings)
        let data1 = []
        this.state.category && this.state.category.map((data, i) => {
            return data1.push({

                "id": data._id,
                "name": data.Name,
                "status":data.Active ?"Active":"Deactive"
                // "status":data.Active ? "Active" : "Deactive"
            })
        })
        this.setState({
            records: data1
        })

    }
    editTag=async (e)=>{
        e.preventDefault()
        let data={
            id:this.state.id,
            Name:this.state.tag,
        }
        console.log(data)
        await axios.post(`${process.env.REACT_APP_API}/api/auth/updateblogcategory`,data,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("admin_token")}`
          }
          }).then((res) => {
          if (res.data.Error == false) {
              this.getBlogCat()
              this.setState({visible:false})
          }
          else if(res.data.token==false){
            localStorage.removeItem("admin_token")
            this.props.history.push("/auth/login")
          }
      })
    
      }
      delete=async()=>{
        let data={
            id:this.state.id
        }
        await axios.post(`${process.env.REACT_APP_API}/api/auth/blogcategorystatus`,data,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("admin_token")}`
          }
          }).then((res) => {
            if (res.data.Error == false) {
         
            }
            else if(res.data.token==false){
                localStorage.removeItem("admin_token")
                this.props.history.push("/auth/login")
              }
        })
    }
    render() {
        return (

                <>
            <Header />
                <div className="mt--7 container-fluid">
                    <div className="shadow card">
                        <div className="border-0 card-header d-flex align-items-center justify-content-between">
                            <h3 class="mb-0"> Blog Categories</h3>
                            <Link to="/admin/addcategory" className="btn btn-primary">Add Category</Link>
                        </div>
                        {this.state.loading ?
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            extraButtons={this.extraButtons}
                        />
                                                  :              
                                      <div className="loader">
                                       <Loader
                                       type="Puff"
                                       color="#00BFFF"
                                       height={100}
                                       width={100}
                                     />
                                     </div>
                        }
                    </div>
                </div>
                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} width="900" height="600" >
                    <div className="header_view_popup">View Detail</div>
                    <div className="main_listing_view">
                        <div className="listing_goes">
                        <form>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="Name">Name</label>
                                                <input value={this.state.tag} onChange={(e)=>{this.setState({tag:e.target.value})}} type="text" className="form-control" id="Name" />
                                            </div>
                                        </div>
                                        <div style={{cursor:"pointer",marginBottom:"20px"}} class="custom-control custom-switch">
                                       <input style={{cursor:"pointer"}}  value={this.state.delete} checked={this.state.delete}  onChange={()=>{this.delete(); this.setState({delete:!this.state.delete})}} type="checkbox" class="custom-control-input" id={`customSwitch1`} />
                                       <label style={{cursor:"pointer"}} class="custom-control-label"  htmlFor={`customSwitch1`}>Status</label>
                                           </div>
                                        <div className="submit_plan text-right">
                                            <button onClick={this.editTag} type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                        </form>
                        </div>
                    </div>
                </Rodal>
                {
                    this.state.DeleteModal ?
                        <Rodal visible={this.state.DeleteModal} onClose={()=>this.setState({DeleteModal:false})} width="600" height="300" >
                            <div className="header_view_popup">Delete Confirmation</div>
                            <div className="main_listing_view">
                                <div className="listing_goes mb-4">
                                Are You Sure Want To Delete? 
                                </div>
                                <button onClick={this.deleteData} type="submit" className="btn btn-primary mt-4">Submit</button>
                            </div>
                        </Rodal>
                        :
                        ""

                }

            </>
                

        )
    }

}

export default Categories;